import { storesRandomByCategoryList } from '../api/index';
import{ orderStoreByLocation } from '../util/anyFunctions';

const coords={ lat: -12.085845, lng: -76.977371 }

const useStoresRandomByCategoryList = async() =>{
    const response = await storesRandomByCategoryList();
    let stores = []
    if(response.status !== "fail"){
        stores = orderStoreByLocation(response?.data?.categories, coords.lat, coords.lng)
    }



    return {stores}
}

export default useStoresRandomByCategoryList;